@font-face {
  font-family: "Galeana";
  src: url("/src/assets/fonts/galeana/regular/GaleanaCompressed-RegularIt.otf") format("opentype"),
    url("/src/assets/fonts/galeana/regular/GaleanaCompressed-RegularIt.woff") format("woff"),
    url("/src/assets/fonts/galeana/regular/GaleanaCompressed-RegularIt.woff2") format("woff2"),
    url("/src/assets/fonts/galeana/regular/GaleanaCompressed-RegularIt.eot") format("embedded-opentype"),
    url("/src/assets/fonts/galeana/regular/GaleanaCompressed-RegularIt.ttf") format("truetype"),
    url("/src/assets/fonts/galeana/regular/GaleanaCompressed-RegularIt.svg") format("svg");
  font-weight: normal;
  font-style: italic;
  font-display: auto;
}

@font-face {
  font-family: "Galeana";
  src: url("/src/assets/fonts/galeana/black/GaleanaCompressed-BlackIt.otf") format("opentype"),
  url("/src/assets/fonts/galeana/black/GaleanaCompressed-BlackIt.woff") format("woff"),
  url("/src/assets/fonts/galeana/black/GaleanaCompressed-BlackIt.woff2") format("woff2"),
  url("/src/assets/fonts/galeana/black/GaleanaCompressed-BlackIt.eot") format("embedded-opentype"),
  url("/src/assets/fonts/galeana/black/GaleanaCompressed-BlackIt.ttf") format("truetype"),
  url("/src/assets/fonts/galeana/black/GaleanaCompressed-BlackIt.svg") format("svg");
  font-weight: bold;
  font-style: italic;
  font-display: auto;
}
